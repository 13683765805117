import { AuthClient } from "../../../api/api";

export const getItem = (id) => {
  return AuthClient().get(`/v2/service-and-item${id}`);
};

export const createItem = (data) => {
  return AuthClient().post(`/v2/service-and-item`, data);
};

export const getAllItem = (type, limit, page, sortBy, search) => {
  return AuthClient().get(
    `/v2/service-and-item?${type}&limit=${limit}&page=${page}&sort=${sortBy}`
  );
};

export const  getAllAndServiceItem = (
  limit,
  page,
  sortBy,
  in_income,
  in_expense,
  search
) => {
  return AuthClient().get(
    `/v2/service-and-item?limit=${limit}&page=${page}&sort=${sortBy}&in_income=${in_income}&in_expense=${in_expense}&searchFields=${search}`
  );
};

export const updateItem = (data, id) => {
  return AuthClient().patch(`/v2/service-and-item/${id}`, data);
};

// export const uploadImage = (name, data) => {
//     return AuthClient().post(`/v2/service-and-item${name}/add-image`, data)
// }

export const deleteItem = (id) => {
  return AuthClient().delete(`/v2/service-and-item/${id}`);
};

// export const deleteImageApi = (name, image) => {
//     return AuthClient().delete(`v2/billboard/${name}/remove-image/${image}`)
// }
